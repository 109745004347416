.home-content-container {
  color: rgba(0, 0, 0, 0.7);
  transition: all 0.5s ease-in-out;
  @media screen and (min-width: 768px) {
    /* transform: translateX(300px); */
    margin-left: 300px;
  }
}

.slideRight {
  @media screen and (max-width: 768px) {
    transform: translateX(300px);
    /* margin-left: 300px; */
  }
}