@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700");
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700");


@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-base: "Playfair Display", Georgia, serif;
  --font-second: "Quicksand", Georgia, serif;
  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

.font-base {
  font-family: var(--font-base);
}

* {
  font-family: var(--font-second);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
