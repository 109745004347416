/* .app__skills {
  opacity: 1;
} */


.app__skills .title {
  margin-left: 50px;
  margin-bottom: 40px;
  font-family: "Playfair Display", serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 2px;
}

.app__skills .skills_container {
  width: 100%;
  padding-left: 50px;
  padding-right: 40px;
}

.app__skills .skills_container .skill {
  padding-left: 15px;
  padding-right: 15px;
  margin: 10px 0px 30px 0px;
  font-size: 14;
  font-family: "Quicksand", Arial, sans-serif;
  font-weight: 600;
}

.score {
  height: 6px;
  background-color: #f2f3f7;
  border-radius: 4px;
}

.score_bar {
  height: 100%;
  border-radius: 8px;
  position: relative;
  animation: scoreAnim 1s ease-in-out forwards;
}

.score_bar.color-1 {
  background: #2c98f0;
}
.score_bar.color-2 {
  background: #ec5453;
}
.score_bar.color-3 {
  background: #f9bf3f;
}
.score_bar.color-4 {
  background: #a84cb8;
}
.score_bar.color-5 {
  background: #2fa499;
}
.score_bar.color-6 {
  background: #4054b2;
}

.score_bar::after {
  position: absolute;
  background-color: inherit;
  right: 0;
  width: 10px;
  height: 10px;
  top: -2px;
  content: "";
  border-radius: 50%;
  animation: scoreBallAnim 1s ease-in-out forwards;
}

.score_bar span {
  position: absolute;
  top: -28px;
  right: -15px;
}

@media screen and (min-width: 768px) {
  .app__skills .skills_container .skill {
    float: left;
    width: 50%;
  }
}

@keyframes scoreAnim {
  0% {
    width: 120%;

  }

  80% {
    width: 120%;

  }

  100% {
    width: 100%;
  }
}

@keyframes scoreBallAnim {
  0% {
    background-color: black;
    right: 100%;
    
  }

  70% {
    right: 0%;
  }

  100% {
    background-color: inherit;
  }
}