@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
.app__header {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.app__header .info.visible {
  animation: goIn 1s ease-in-out forwards;
}

.app__header img {
  width: 100%;
  height: 100%;
  min-height: 400px;
  object-fit: cover;
  animation: none;
}

.app__header .info {
  position: absolute;
}

.app__header .name {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 30px;
  line-height: 1.2;
  text-align: center;
  opacity: 1;
  justify-content: center;
  /* animation: goIn 1s ease-in-out forwards; */
}

.app__header .short-dis {
  font-size: 18px;
  /* animation: goIn 1.25s ease-in-out forwards; */
}

@keyframes goIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (min-width: 768px) {
  .app__header {
    align-items: start;
  }

  .app__header .info {
    left: 40px;
  }

  .app__header .name {
    text-align: start;
    font-size: 50px;
  }

  @keyframes goIn {
    0% {
      opacity: 0;
      transform: translateX(-15%);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
